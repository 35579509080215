/**
 * Syntax highlighting styles
 */

/* `highlighted` text */
code.highlighter-rouge {
    padding: 0.1em 0.3em;
    font-size: 0.8em;
    color: $inline-code-text-color;
    background-color: $inline-code-background-color;
    border: 1px solid $inline-code-accent-color;
}
/* `highlighted` text in headers or links */
h1,h2,h3,h4,h5,h6,a {
    code.highlighter-rouge {
        padding: 0.1em;
        font-size: 0.9em;
        color: inherit;
        background-color:inherit;
        border: none;
    }
}

table.rouge-table {
    line-height: 1.2em;
    border: none;
    .rouge-gutter {
        font-size: smaller;
        font-weight:bolder;
        padding-right: 1em;
        text-align: right;
    }
    td {
        border: none;
        vertical-align: baseline;
    }
}

pre.highlight {
    .highlighter-rouge & {
        border-left: 4px solid $embedded-code-accent-color;
        margin: 1em 0;
        overflow: auto;
        padding: 1em;
        background-color: $embedded-code-background-color;
        color: $embedded-code-text-color;
        border-radius: 3px;
    }
}
