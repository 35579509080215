@charset "utf-8";

$base-font-family: "Source Serif Pro",serif;
$header-font-family: "Source Sans Pro",sans-serif;
$code-font-family: "Source Code Pro",monospace;
$base-font-size:   16px;
$base-font-weight: 400;
$small-font-size:  $base-font-size * 0.875;
$base-line-height: 1.5;
$footer-height: 100px;

$spacing-unit:     30px;

// Width of the content area
$content-width:    1000px;
$on-palm:          600px;
$on-laptop:        800px;

$header-height:     $base-font-size * 2;

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: calc($spacing-unit / 2);
//         padding-left: calc($spacing-unit / 2);
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout"
;
